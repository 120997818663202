import sponsor from "../assets/sponsoring/sponsor.png";
import offensivsport from '../assets/sponsoring/offensivsport-black.png';
import bierhandlung from '../assets/sponsoring/Bierhandlung.jpg';
import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";

function Sponsoring() {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const cloneContainer = container.cloneNode(true);
      container.parentNode.appendChild(cloneContainer);
    }
  }, []);

  const handleBannerClick = (event) => {
    event.stopPropagation(); // Stoppt das Event-Bubbling
    // Optional: hier könnte ein Tracking-Aufruf gemacht werden oder etwas anderes.
    // Die Animation wird fortgesetzt.
  };

  return (
    <div className="container-fluid logos shadow-sm" onClick={handleBannerClick} >
      <div className="row rakete-font logos-slide my-2 ms-3" ref={containerRef}>
        <Link to="/sponsor">
          <img src={sponsor} className="sponsorwanted logo" alt="Sponsor gesucht" onClick={handleBannerClick} />
        </Link>
        <Link to="https://offensiv-sport.de/" target="_blank" rel="">
          <img src={offensivsport} className="os logo" alt="Offensiv Sport" onClick={handleBannerClick}/>
        </Link>
        <Link to="https://www.bierhandlung.de/" target="_blank" rel="">
          <img src={bierhandlung} className="logo" alt="Bierhandlung Freiburg" onClick={handleBannerClick}/>
        </Link>
        <Link to="/sponsor">
          <img src={sponsor} className="sponsorwanted logo" alt="Sponsor gesucht" onClick={handleBannerClick} />
        </Link>
        <Link to="https://offensiv-sport.de/" target="_blank" rel="">
          <img src={offensivsport} className="os logo" alt="Offensiv Sport" onClick={handleBannerClick}/>
        </Link>
        <Link to="https://www.bierhandlung.de/" target="_blank" rel="">
          <img src={bierhandlung} className="logo" alt="Bierhandlung Freiburg" onClick={handleBannerClick}/>
        </Link>
      </div>
    </div>
  );
}

export default Sponsoring;
