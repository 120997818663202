import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CountUpAnimation from '../scripts/countup';

function Counter() {
  const [memberCount, setMemberCount] = useState(0); // Zustand für Mitgliederanzahl
  const year = new Date().getFullYear() - 1996; // Jahre Berechnung
  
  useEffect(() => {
    // Funktion zum Laden der Mitgliederanzahl
    const loadMemberCount = async () => {
      try {
        const response = await axios.get('https://server.raketefreiburg.de/members/member-count'); // Aufruf der API für die Gesamtmitgliederzahl
        setMemberCount(response.data.overall); // Setze die Mitgliederanzahl in den Zustand
      } catch (error) {
        console.error('Fehler beim Laden der Mitgliederanzahl:', error);
      }
    };

    loadMemberCount(); // API-Aufruf zum Laden der Daten
  }, []); // Leerer Dependency-Array, damit es nur einmal bei der Initialisierung läuft

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <h1 className="text-center rakete-font rakete-rot"><b><CountUpAnimation>{year}</CountUpAnimation></b></h1>
          <p className="text-center rakete-font fw-bold mb-5">JAHRE</p>
        </div>
        <div className="col">
          <h1 className="text-center rakete-rot rakete-font" id="Count"><b><CountUpAnimation>{memberCount}</CountUpAnimation></b></h1> {/* Dynamische Mitgliederanzahl */}
          <p className="text-center rakete-font mb-5"><b>MITGLIEDER</b></p>
        </div>
        <div className="col">
          <h1 className="text-center rakete-rot rakete-font" id="Count"><b><CountUpAnimation>4</CountUpAnimation></b></h1>
          <p className="text-center rakete-font mb-5"><b>TEAMS</b></p>
        </div>
      </div>
    </div>
  );
}

export default Counter;