import React from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from './components/Navbar.jsx';
import Footer from './components/Footer.jsx';
import Header from './components/Header.jsx';
import Home from './pages/Home.jsx';
import Club from './pages/Club.jsx';
import Event from './pages/Events.jsx';
import Member from './pages/Member.jsx';
import Datenschutz from './pages/Datenschutz.jsx';
import Impressum from './pages/Impressum.jsx';
import Erste from "./pages/Erste.jsx";
import Zweite from "./pages/Zweite.jsx";
import Mixed from "./pages/Mixed.jsx";
import Frauen from "./pages/Frauen.jsx";
import ScrollToTop from "./components/ScrollToTop.js";

function App () {
  const hallo = "hola"
  return (
    <div className="App max-size mx-auto shadow">
      <ScrollToTop/>
        <NavBar />
        <Header />       
        <Routes>
          <Route index element={<Home />}/>
          <Route path="/club" element={<Club />}/>
          <Route path="/events" element={<Event />}/>
          <Route path="/erste" element={<Erste hallo={hallo}/>}/>
          <Route path="/zweite" element={<Zweite />}/>
          <Route path="/mixed" element={<Mixed/>}/>
          <Route path="/frauen" element={<Frauen />}/>
          <Route path="/member" element={<Member/>}/>
          <Route path="/datenschutz" element={<Datenschutz/>}/>
          <Route path="/impressum" element={<Impressum/>}/>
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
