import sponsor from "../assets/sponsoring/sponsor.png";
import offensivsport from '../assets/sponsoring/offensivsport-black.png';
import React, { useEffect } from 'react';
import {Link} from "react-router-dom";

function Sponsoring() {
  useEffect(() => {
    const container = document.querySelector('.logos-slide');

    if (container) {
      const cloneContainer = container.cloneNode(true);
      container.parentNode.appendChild(cloneContainer);
    }
  }, []);

  return (
    <div className="container-fluid logos shadow-sm">
      <div className="row rakete-font my-4 logos-slide">
        <Link to="#" target="_blank" rel="">
          <img src={sponsor} className="sponsorwanted logo" alt="Sponsor gesucht" />
        </Link>
        <Link to="https://offensiv-sport.de/" target="_blank" rel="">
          <img src={offensivsport} className="os logo" alt="Offensiv Sport" />
        </Link>
        <Link to="#" target="_blank" rel="">
          <img src={sponsor} className="sponsorwanted logo" alt="Sponsor gesucht" />
        </Link>
        <Link to="https://offensiv-sport.de/" target="_blank" rel="">
          <img src={offensivsport} className="os logo" alt="Offensiv Sport" />
        </Link>
      </div>
    </div>
  );
}

export default Sponsoring;