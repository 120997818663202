import CountUpAnimation from '../scripts/countup';


function Counter() {
  const year = new Date().getFullYear() - 1996;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <h1 className="text-center rakete-font rakete-rot"><b><CountUpAnimation>{year}</CountUpAnimation></b></h1>
          <p className="text-center rakete-font fw-bold mb-5">JAHRE</p>
        </div>
        <div className="col">
          <h1 className="text-center rakete-rot rakete-font" id="Count"><b><CountUpAnimation>150</CountUpAnimation></b></h1>
          <p className="text-center rakete-font mb-5"><b>MITGLIEDER</b></p>
        </div>
        <div className="col">
          <h1 className="text-center rakete-rot rakete-font" id="Count"><b><CountUpAnimation>4</CountUpAnimation></b></h1>
          <p className="text-center rakete-font mb-5"><b>TEAMS</b></p>
        </div>
      </div>
    </div>
  );
}

export default Counter;