import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import * as yup from 'yup';

function Memberform() {
    const [loading, setLoading] = useState(false);

    // Funktion zum Senden der E-Mail
    const sendEmail = async (formData) => {
        setLoading(true);
        try {
            // Hier die POST-Anfrage mit den Formulardaten an den Server senden
            const response = await axios.post(`https://server.raketefreiburg.de/mail/send-email`, formData);
            console.log('Erfolgreich gesendet:', response.data); // Konsolenausgabe für Erfolgsmeldung
            setLoading(false);
            alert('E-Mail wurde erfolgreich gesendet!'); // Benachrichtigung für den Benutzer
            // Hier könnte optional eine Weiterleitung oder eine andere Aktion erfolgen
        } catch (error) {
            setLoading(false);
            console.error('Fehler beim Senden der E-Mail:', error); // Konsolenausgabe für Fehler
            alert('Beim Senden der E-Mail ist ein Fehler aufgetreten.'); // Fehlermeldung für den Benutzer
        }
    };

    // Schema für die Validierung der Formulardaten
    const schema = yup.object().shape({
        firstname: yup.string().required('Vorname ist erforderlich'),
        lastname: yup.string().required('Nachname ist erforderlich'),
        email: yup.string().email('Ungültige E-Mail-Adresse').required('E-Mail ist erforderlich'),
        mannschaft: yup.string().oneOf(["1", "2", "3", "4"], "Ungültige Mannschaft").required('Mannschaft ist erforderlich'),
        message: yup.string().required('Nachricht ist erforderlich'),
    });

    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values, { resetForm }) => {
                sendEmail(values); // Aufruf der sendEmail-Funktion mit den Formulardaten
                resetForm(); // Zurücksetzen des Formulars nach dem Absenden
            }}
            initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                mannschaft: '',
                message: '',
            }}
        >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3 mx-1">
                        <Form.Group as={Col} md="6" controlId="validationFormik01">
                            <Form.Label className='fw-semibold'>Vorname</Form.Label>
                            <Form.Control
                                type="text"
                                name="firstname"
                                value={values.firstname}
                                onChange={handleChange}
                                isValid={touched.firstname && !errors.firstname}
                                isInvalid={touched.firstname && !!errors.firstname}
                                placeholder="Max"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.firstname}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationFormik02">
                            <Form.Label className='fw-semibold'>Nachname</Form.Label>
                            <Form.Control
                                type="text"
                                name="lastname"
                                value={values.lastname}
                                onChange={handleChange}
                                isValid={touched.lastname && !errors.lastname}
                                isInvalid={touched.lastname && !!errors.lastname}
                                placeholder="Mustermann"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.lastname}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mx-1">
                        <Form.Group controlId="validationFormik03">
                            <Form.Label className='fw-semibold'>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isValid={touched.email && !errors.email}
                                isInvalid={touched.email && !!errors.email}
                                placeholder="max.mustermann@beispiel.de"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mx-1">
                        <Form.Group className="mb-3" controlId="validationFormik04">
                            <Form.Label className='fw-semibold'>Mannschaft</Form.Label>
                            <Form.Select
                                name="mannschaft"
                                value={values.mannschaft}
                                onChange={handleChange}
                                isValid={touched.mannschaft && !errors.mannschaft}
                                isInvalid={touched.mannschaft && !!errors.mannschaft}
                                placeholder="Wähle eine Mannschaft aus"
                            >
                                <option value="">Wähle eine Mannschaft aus</option>
                                <option value="1">Erste</option>
                                <option value="2">Zweite</option>
                                <option value="3">Frauen</option>
                                <option value="4">Mixed</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errors.mannschaft}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mx-1">
                        <Form.Group controlId="validationFormik05">
                            <Form.Label className='fw-semibold'>Nachricht</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="message"
                                value={values.message}
                                onChange={handleChange}
                                isValid={touched.message && !errors.message}
                                isInvalid={touched.message && !!errors.message}
                                rows={3}
                                placeholder="Schreibe hier deine Nachricht"
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className='py-3 mx-3'>
                        <Button type="submit" variant="danger" className='rakete-font text-uppercase shadow-sm'>
                            {loading ? 'Wird gesendet...' : 'Mitkicken!'}
                        </Button>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default Memberform;
