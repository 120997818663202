function Titlebar ({title}) {
    return (
        <div className="bg-rakete shadow-sm">
            <div className="pt-3 pb-2">
                <h5 className="text-center rakete-font text-white text-uppercase"><b>{title}</b></h5>
            </div>
        </div>
    )
}

export default Titlebar;