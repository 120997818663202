import React from "react";
import Titlebar from "../components/Title.jsx";
import { useState, useEffect } from "react";
import axios from "axios";
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';

function Club() {
    const [loading, setLoading] = useState(false);
    const [member, setMember] = useState([]);
    const [history, setHistory] = useState([]);

    const vorstand = member.sort((a, b) => a.role - b.role).filter(member => member.role === 1 || member.role === 2 || member.role === 3);

    const erste = member
        .filter(member => member.role === 4)
        .map(member => member.forename);
    const zweite = member
        .filter(member => member.role === 5)
        .map(member => member.forename);
    const mixed = member
        .filter(member => member.role === 6)
        .map(member => member.forename);
    const frauen = member
        .filter(member => member.role === 7)
        .map(member => member.forename);

    useEffect(() => {
        setLoading(true);
        axios
            .get('https://server.raketefreiburg.de/members/summary/all')
            .then((response) => {
                ;
                setMember(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios
            .get('https://server.raketefreiburg.de/api/History')
            .then((response) => {
                ;
                setHistory(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const sortedHistory = history.sort((a, b) => a.year - b.year);

    return (
        <div>
            <Titlebar title="VEREIN" />
            <p className="lh-1 rakete-font fs-2 pt-4 text-center fw-semibold">GESCHICHTE</p>
            <div className="container mt-3">
                <div className="main-timeline">
                    {sortedHistory.slice(0, 2).map((history, index) => (
                        <div className="timeline" id={index}>
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                        <span className="month rakete-font">JAHR</span>
                                        <span className="year rakete-font">{history.year}</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content mt-4">
                                <h5 className="title rakete-font text-uppercase">
                                    {history.title}
                                </h5>
                                <p className="description"><ReactMarkdown>{history.content}</ReactMarkdown>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-2 rounded bg-light mx-auto w-1000 shadow">
                <div className="mt-1 mx-auto my-2 mb-5 container-fluid">
                    <h3 className="rakete-font pt-3 rakete-rot"><b>VORSTAND</b></h3>
                    <div className="row">
                        {vorstand.map((vorstand, index) => (
                            <div className="col my-3" index={index}>
                                <div className="card news-card">
                                    <img src={`https://server.raketefreiburg.de/uploads/${vorstand.picture}`} className="card-img-top vorstand-ratio" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title text-center">{vorstand.forename} {vorstand.lastname}</h5>
                                        <p className="card-text text-center">{vorstand.role}. Vorstand</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="container mt-3">
                <div className="main-timeline">
                    {sortedHistory.slice(2, 5).map((history, index) => (
                        <div className="timeline" id={index}>
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                        <span className="month rakete-font">JAHR</span>
                                        <span className="year rakete-font">{history.year}</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content mt-4">
                                <h5 className="title rakete-font text-uppercase">
                                    {history.title}
                                </h5>
                                <p className="description">{history.content}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mt-2 border-2 border-dark rounded bg-light shadow mx-auto w-1000">
                <div className="mt-1 mx-auto my-2 mb-5 container-fluid">
                    <h3 className="rakete-font pt-3 rakete-rot text-end"><b>LEITUNG</b></h3>
                    <div className="row">
                        <div className="col my-3">
                            <div className="card news-card">
                                <img src="https://placehold.co/200x200?text=Leitung" className="card-img-top card-img-top vorstand-ratio" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title text-center">
                                        {erste.length > 1 ? `${erste.slice(0, -1).join(', ')} & ${erste[erste.length - 1]}` : erste[0]}
                                    </h5>
                                    <p className="card-text text-center">Die Erste</p>
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                            <div className="card news-card">
                                <img src="https://placehold.co/200x200?text=Leitung" className="card-img-top vorstand-ratio" alt="..." />
                                <div className="card-body">
                                <h5 className="card-title text-center">
                                        {zweite.length > 1 ? `${zweite.slice(0, -1).join(', ')} & ${zweite[zweite.length - 1]}` : zweite[0]}
                                    </h5>
                                    <p className="card-text text-center">Die Zweite</p>
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                            <div className="card news-card">
                                <img src="https://server.raketefreiburg.de/uploads/leitung-mixed.jpeg" className="card-img-top vorstand-ratio" alt="..." />
                                <div className="card-body">
                                <h5 className="card-title text-center">
                                        {mixed.length > 1 ? `${mixed.slice(0, -1).join(', ')} & ${mixed[mixed.length - 1]}` : mixed[0]}
                                    </h5>
                                    <p className="card-text text-center">Mixed</p>
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                            <div className="card news-card">
                                <img src="https://placehold.co/200x200?text=Leitung" className="card-img-top vorstand-ratio" alt="..." />
                                <div className="card-body">
                                <h5 className="card-title text-center">
                                        {frauen.length > 1 ? `${frauen.slice(0, -1).join(', ')} & ${frauen[frauen.length - 1]}` : frauen[0]}
                                    </h5>
                                    <p className="card-text text-center">Frauen</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-3">
                <div className="main-timeline">
                    {sortedHistory.slice(5).map((history, index) => (
                        <div className="timeline" id={index}>
                            <div className="icon"></div>
                            <div className="date-content">
                                <div className="date-outer">
                                    <span className="date">
                                        <span className="month rakete-font">JAHR</span>
                                        <span className="year rakete-font">{history.year}</span>
                                    </span>
                                </div>
                            </div>
                            <div className="timeline-content mt-4">
                                <h5 className="title rakete-font text-uppercase">
                                    {history.title}
                                </h5>
                                <p className="description">{history.content}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <h1 className="rakete-font rakete-rot text-center mb-5 fw-bold">TO BE CONTINUED...</h1>
            </div>
        </div>
    );
}

export default Club;