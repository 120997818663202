import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { HiArrowCircleRight, HiArrowCircleLeft } from "react-icons/hi";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function NewsDetail({ show, onHide, news }) {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [PicturesPerPage, setPicturesPerPage] = useState(3);
  const [selectedPicture, setSelectedPicture] = useState(null);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize < 1000) {
      setPicturesPerPage(1);
      setCurrentIndex(0);
    } else {
      setPicturesPerPage(3);
    }
  }, [screenSize]);

  if (!news) {
    return null;
  }

  const formattedDate = (date) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    return new Date(date).toLocaleDateString('de-DE', options);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= news.length) {
        return 0;
      } else {
        return newIndex;
      }
    });
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => {
      let newIndex = prevIndex - 1;
      if (newIndex < 0) {
        newIndex = news.length - 1;
      }
      return newIndex;
    });
  };

  const handleClick = (index) => {
    setSelectedPicture(news.gallery[index]);
  };

  const handleClose = () => {
    setSelectedPicture(0);
    setCurrentIndex(0);
    onHide();
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose} centered className='no-carot'>
      <Modal.Header className='bg-light justify-content-center border border-0 rakete-rot' closeButton>
        <Modal.Title className='rakete-font fw-semibold text-uppercase'>{news.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-light shadow'>
        <div className='mx-2'>
          <img src={`https://server.raketefreiburg.de/uploads/${news.picture}`} className="img-fluid rounded shadow-sm border" alt={`News: ${news.title}`} />
          <p className="fs-7 fw-semibold text-end mt-2">{formattedDate(news.date)}</p>
          <p className='fw-semibold fs-5 text-center'>{news.subtitle}</p>
          <div dangerouslySetInnerHTML={{ __html: news.content }} />
        </div>
        {news.gallery.length !== 0 && (
          <div className="mx-auto mb-5 container-fluid position-relative">
            <h4 className="rakete-font fw-semibold pt-3 ms-4 rakete-rot text-center mb-3">Galerie</h4>
            <div className="row justify-content-center align-items-center">
              {news.gallery.slice(currentIndex, currentIndex + PicturesPerPage).map((picture, index) => (
                <div key={index} className={screenSize >= 1000 ? "col-4" : ""}>
                  <div className={screenSize < 1000 ? "mx-5" : "h-100"}>
                    <img src={`https://server.raketefreiburg.de/uploads/${picture}`} className="card-img-top rounded news-ratio" alt={`News: ${news.title}`} />
                  </div>
                </div>
              ))}
              {currentIndex > 0 && (
                <button className="carousel-control-prev" type="button" onClick={prevImage}>
                  <HiArrowCircleLeft className='rakete-rot fs-1 mt-5' />
                  <span className="visually-hidden">Previous</span>
                </button>
              )}
              {currentIndex + PicturesPerPage < news.gallery.length && (
                <button className="carousel-control-next" type="button" onClick={nextImage}>
                  <HiArrowCircleRight className='rakete-rot fs-1 mt-5' />
                  <span className="visually-hidden">Next</span>
                </button>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className='border border-0 fs-7'>Verfasser: {news.author}</Modal.Footer>
    </Modal>
  );
}

function News() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedNews, setSelectedNews] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [newsPerPage, setNewsPerPage] = useState(3);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tournament, setTournament] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchNewsAndTournaments = async () => {
      try {
        const [newsResponse, tournamentsResponse] = await Promise.all([
          axios.get('https://server.raketefreiburg.de/api/News'),
          axios.get('https://server.raketefreiburg.de/api/Tournaments')
        ]);

        const sortedNews = newsResponse.data.data.sort((a, b) => a.index - b.index);

        // Filtere die Turniere, die noch nicht abgeschlossen sind (completed = false)
        const upcomingTournaments = tournamentsResponse.data.data
          .filter(tournament => !tournament.completed) // Nur Turniere, die nicht abgeschlossen sind
          .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sortiere nach Datum (nächstes Turnier zuerst)

        // Nimm das erste Turnier, das das nächstgelegene Datum hat
        const nearestTournament = upcomingTournaments.length > 0 ? upcomingTournaments[0] : null;
        if (nearestTournament !== null) {
          setTournament(true)
        }

        // Kombiniere die News mit dem gefilterten und sortierten Turnier
        const combinedContent = nearestTournament ? [nearestTournament, ...sortedNews] : [...sortedNews];

        setContent(combinedContent);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchNewsAndTournaments();
  }, []);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize < 1000) {
      setNewsPerPage(1);
    } else {
      setNewsPerPage(3);
    }
  }, [screenSize]);

  const nextContent = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= content.length) {
        return 0;
      } else {
        return newIndex;
      }
    });
  };

  const prevContent = () => {
    setCurrentIndex((prevIndex) => {
      let newIndex = prevIndex - 1;
      if (newIndex < 0) {
        newIndex = content.length - 1;
      }
      return newIndex;
    });
  };

  const handleContentClick = (index) => {
    setSelectedNews(content[index]);
    setShowDetail(true);
  };

  const handleHideDetail = () => {
    setShowDetail(false);
  };

  const handleTournamentClick = (id) => {
    navigate(`/tournament/${id}`);
  }

  return (
    <div className="bg-light shadow">
      <div className="mx-auto mb-5 container-fluid position-relative">
        <h4 className="rakete-font fw-semibold pt-3 ms-4 rakete-rot anne">NEWS</h4>
        <div className="row justify-content-center my-3">
          {content.slice(currentIndex, currentIndex + newsPerPage).map((item, index) => (
            <div key={index} className={screenSize >= 1000 ? "col-3 mb-5 " : "news-max"}>
              <div className={screenSize < 1000 ? "mx-5 mb-5" : "h-100"}>
                {tournament === true && index === 0 ?
                  <div className="card news-card shadow h-100 hover" onClick={() => handleTournamentClick(item._id)}>
                    <img src={`https://server.raketefreiburg.de/uploads/${item.picture}`} className="card-img-top news-ratio" alt={`Content: ${item.title}`} />
                    <div className="card-body">
                      <h5 className="text-center fs-6 text-uppercase">Jetzt Anmelden</h5>
                    </div>
                  </div>
                  :
                  <div className="card news-card shadow h-100 hover" onClick={() => handleContentClick(currentIndex + index)}>
                    <img src={`https://server.raketefreiburg.de/uploads/${item.picture}`} className="card-img-top news-ratio" alt={`Content: ${item.title}`} />
                    <div className="card-body">
                      <h5 className="text-center fs-6 text-uppercase">{item.title}</h5>
                    </div>
                  </div>}
              </div>
            </div>
          ))}
        </div>
        {currentIndex > 0 && (
          <button className="carousel-control-prev" type="button" onClick={prevContent}>
            <HiArrowCircleLeft className='rakete-rot fs-1' />
            <span className="visually-hidden">Previous</span>
          </button>
        )}
        {currentIndex + newsPerPage < content.length && (
          <button className="carousel-control-next" type="button" onClick={nextContent}>
            <HiArrowCircleRight className='rakete-rot fs-1' />
            <span className="visually-hidden">Next</span>
          </button>
        )}
      </div>
      <NewsDetail show={showDetail} onHide={handleHideDetail} news={selectedNews} />
    </div>
  );
}

export default News;
