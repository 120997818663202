import Memberform from "../components/form"
import Titlebar from "../components/Title.jsx";
function Member() {
    return (
        <div>
            <Titlebar title="mitkicken" />

            <div className="bg-white">
                <div className="container pt-3">
                    <div className="row mt-2 justify-content-center py-4 rounded shadow-sm mx-3">
                        <div className="col-md">
                            <div>
                                <p className="rakete-font fs-6 fw-bold text-center text-uppercase rakete-rot">Fussball und Gemeinschaft!</p>
                                <div className="text-justify ">
                                    <p>
                                        Bei uns dreht sich alles um Fußball und Gemeinschaft! Mit bis zu 3 Trainings pro Woche in 4 verschiedenen Mannschaften bieten wir nicht nur sportliche Herausforderungen, sondern auch eine starke Gemeinschaft. Der Teamgeist und die Freude am Spiel stehen bei uns im Mittelpunkt.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            <div>
                                <p className="rakete-font fs-6 fw-bold text-center text-uppercase rakete-rot">Auf und neben dem Spielfeld aktiv!</p>
                                <div className="text-justify">
                                    <p>
                                        Neben Turnieren wie dem Raketen-Cup und dem Mittsommer-Cup organisieren wir auch den "Wir-sind-ein-Team-Cup". Als Teil der Bunten Liga Freiburg treten wir in spannenden Spielen an. Abseits des Platzes genießen wir unsere Grillfeier und gemütliche Weihnachtsfeiern.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md">
                            <div>
                                <p className="rakete-font fs-6 fw-bold text-center text-uppercase rakete-rot">Werde Teil von Rakete!</p>
                                <div className="text-justify">
                                    <p>
                                        Wir erhalten eine hohe Anzahl an Anfragen, und obwohl wir bemüht sind, allen gerecht zu werden, kann es etwas dauern, bis wir antworten. Sobald ein Platz frei ist, laden wir dich gerne zu einem Probetraining ein. Wenn die Chemie stimmt, heißen wir dich herzlich bei Rakete-Freiburg willkommen!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mx-3 bg-light my-3 rounded shadow">                  
                        <div className="col-md-6 rounded my-4 me-3">
                        <p className="text-center text-uppercase rakete-font fs-2 fw-semibold rakete-rot">Kick mit!</p>
                            <Memberform/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Member;