import { Link } from 'react-router-dom';

function Footer() { 
  return (
    <footer>
      <ul className="nav justify-content-center border-bottom mx-5 mt-3">
        <li className="nav-item"><Link to="impressum" className="nav-link px-2 text-muted">Impressum</Link></li>
        <li className="nav-item"><Link to="member" className="nav-link px-2 text-muted">Kontakt</Link></li>
        <li className="nav-item"><Link to="datenschutz" className="nav-link px-2 text-muted">Datenschutz</Link></li>
      </ul>
      <p className="text-center text-muted py-3">© {new Date().getFullYear()} Rakete Freiburg</p>
    </footer>
  );
}

export default Footer;