import React from "react";
import News from '../components/News.jsx';
import Counter from '../components/Counter.jsx';
import TeamsSection from "../components/TeamsSection.jsx";
import Sponsoring from "../components/Sponsoring.jsx";
import Titlebar from "../components/Title.jsx";

function Home() {
    return (
        <div className="max-size">
            <Titlebar title="Willkommen bei HFT Rakete Freiburg e.V." />
            <Sponsoring />
            <News />
                <div>
                    <div className="mx-3 row">
                    <h3 className="rakete-font text-uppercase rakete-rot text-center mt-3 mb-4"><b>DAS IST RAKETE</b></h3>
                        <div className="col-md my-2 py-3 mx-1">
                            <div>
                                <p className="text-center rakete-font text-uppercase fs-6 fw-semibold">"Hier vereinen sich Leidenschaft und Engagement"</p>
                            </div>
                            <div className="text-center">
                                <p className="">In unserem Freizeit-Fußballverein leben wir unsere Leidenschaft für den Hobbyfußball aus. Wir treffen uns, um gemeinsam unserer Begeisterung auf dem Platz nachzugehen.</p>
                            </div>
                        </div>
                        <div className="col-md my-2 py-3 mx-1">
                            <p className="text-center rakete-font text-uppercase fs-6 fw-semibold">"Fußball ist unsere Passion, Freizeit ist unsere Domäne!"</p>
                            <div className="text-center">
                                <p>In 4 dynamischen Teams - von den Männern über Mixed bis zu den Frauen - bieten wir regelmäßiges Training und Spielspaß bis zu dreimal pro Woche!</p>
                            </div>
                        </div>
                        <div className="col-md my-2 py-3 mx-1">
                            <p className="text-center rakete-font text-uppercase fs-6 fw-semibold">"Unsere Vereinsaktivitäten gehen über das Spielfeld hinaus"</p>
                            <div className="text-center">
                                <p> Unsere legendären Turniere, die jährliche Grillfeier und die Weihnachtsfeiern sind Beweis dafür, dass wir nicht nur spielen, sondern auch feiern können!</p>
                            </div>
                        </div>
                    </div>
                <Counter />
            </div>
            <TeamsSection />
        </div>
    );
}

export default Home;