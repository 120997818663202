import Titlebar from "../components/Title.jsx";
import { useState, useEffect } from "react";
import axios from "axios";
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import Modal from 'react-bootstrap/Modal';
import { Accordion } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

function Event() {
    const [games, setGames] = useState([]);
    const [nextGame, setNextGame] = useState({});
    const [lastGame, setLastGame] = useState({});
    const [loading, setLoading] = useState(false);
    const [opponents, setOpponents] = useState([]);
    const [tournaments, setTournaments] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [tournament, setTournament] = useState({});
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();

    const handleHideDetail = () => {
        setShowDetail(false);
    };

    const handleTournamentClick = (id) => {
        navigate(`/tournament/${id}`);
      }

    useEffect(() => {
        setLoading(true);
        axios
            .get('https://server.raketefreiburg.de/api/Games')
            .then((response) => {
                const fetchedGames = response.data.data;
                const sortedGames = fetchedGames.sort((a, b) => new Date(a.date) - new Date(b.date));
                const now = new Date();
                const upcomingGame = sortedGames.find(game => new Date(game.date) > now);
                const previousGame = [...sortedGames].reverse().find(game => new Date(game.date) < now);
                if(!upcomingGame){
                    setNextGame(previousGame);
                    const secondToLastGame = [...sortedGames].reverse().find(game => new Date(game.date) < new Date(previousGame.date));
                    setLastGame(secondToLastGame);
                }else {
                    setNextGame(upcomingGame);
                    setLastGame(previousGame);
                }
                setGames(sortedGames);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios
            .get('https://server.raketefreiburg.de/api/Events')
            .then((response) => {
                const fetchedEvents = response.data.data;
                const sortedEvents = fetchedEvents.sort((a, b) => new Date(a.date) - new Date(b.date));
                const now = new Date();
                setEvents(sortedEvents);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios
            .get('https://server.raketefreiburg.de/api/Opponents')
            .then((response) => {
                setOpponents(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios
            .get('https://server.raketefreiburg.de/tournament')
            .then((response) => {
                const reversedTournaments = response.data.data.reverse();
                setTournaments(reversedTournaments);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);





    const getOpponentData = (opponentId) => {
        const opponent = opponents.find(opponent => opponent._id === opponentId);
        return opponent ? { short: opponent.short, picture: opponent.logo, name: opponent.name } : {};
    };

    const formatGermanDateandTime = (date) => {
        return dayjs(date).locale('de').format('DD.MM.YYYY - HH:mm');
    };

    const formatGermanDate = (date) => {
        return dayjs(date).locale('de').format('DD.MM.YYYY');
    };

    function calculateStatsAndFilterUndefined(games) {
        let p = 0;
        let w = 0;
        let d = 0;
        let l = 0;
        let gd = 0;
        let pts = 0;

        games.forEach(game => {
            if (typeof game.scored === 'number' && typeof game.conceded === 'number') {
                p++;
                if (game.scored > game.conceded) {
                    w++;
                    pts = pts + 2;
                } else if (game.scored === game.conceded) {
                    d++;
                    pts = pts + 1;
                } else {
                    l++;
                }
                gd += game.scored - game.conceded;
            }
        }
        );

        return { p, w, d, l, gd, pts };
    }

    const stats = calculateStatsAndFilterUndefined(games);

    return (
        <>
            <Titlebar title="EVENTS" />
            <div>
                <div className="rounded shadow-sm mt-3 mx-3 pb-4">
                    <p className="lh-1 rakete-font fs-2 pt-4 text-center fw-semibold">BUNTE LIGA</p>
                    <p className="mx-4 fs-6 text-justify pb-4">Die Bunte Liga ist eine informelle Fußballliga in Freiburg. Sie ist eine Art "Freizeitliga", die aus Mannschaften besteht, die sich aus Freizeitfußballern zusammensetzen, die nicht in den offiziellen, regulären Ligen spielen möchten oder können. In der Bunten Liga steht der Spaß am Spiel im Vordergrund, und es geht oft weniger um den Wettbewerb als vielmehr um die Gemeinschaft und das gemeinsame Erleben des Sports.</p>
                    <p className="text-center fw-semibold rakete-font fs-5 rakete-rot">SAISON 2024/25</p>
                    <div className="bg-light border rounded shadow-sm mx-3">
                        <div className="row text-center">
                        </div>

                        <div className="row justify-content-evenly mx-auto">

                            <div className="col-md bg-white shadow-sm border-top py-2">
                                <div className="">
                                    <p className="ms-2 rakete-font fs-6 fw-semibold lh-1 pt-3 text-center rakete-rot">LETZTES SPIEL</p>
                                </div>
                                <div>
                                    <p className=" text-center rakete-font"><b>{formatGermanDate(lastGame.date)}</b><br />{lastGame.location} </p>
                                    <div>
                                        <div className="row rakete-font d-flex align-items-center justify-content-center">
                                            <div className="col-auto">
                                                <img src="https://server.raketefreiburg.de/logo/Rakete_logo_neu.png" className="ticker " alt="..." />
                                            </div>
                                            <div className="col-auto mt-3">
                                                <p className="fs-6">RAK</p>
                                            </div>
                                            <div className="col-auto mt-3">
                                                <p className="rakete-font fw-semibold fs-6">{lastGame.scored}:{lastGame.conceded}</p>
                                            </div>
                                            <div className="col-auto mt-3">
                                                <p className="fs-6">{getOpponentData(lastGame.opponent).short}</p>
                                            </div>
                                            <div className="col-auto">
                                                <img src={`https://server.raketefreiburg.de/uploads/${getOpponentData(lastGame.opponent).picture}`} className="ticker" alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md bg-white shadow-sm border-top py-2">
                                <div className="">
                                    <p className="ms-2 rakete-font fs-6 fw-semibold lh-1 pt-3 text-center rakete-rot">NÄCHSTES SPIEL</p>
                                </div>
                                <p className=" text-center rakete-font"><b>{formatGermanDateandTime(nextGame.date)} Uhr</b><br />{nextGame.location} </p>
                                <div>
                                    <div className="row rakete-font align-items-center justify-content-center">
                                        <div className="col-auto text-end">
                                            <img src="https://server.raketefreiburg.de/logo/Rakete_logo_neu.png" className="ticker" alt="..." />
                                        </div>
                                        <div className="col-auto mt-3">
                                            <p className="fs-6">RAK</p>
                                        </div>
                                        <div className="col-auto mt-3">
                                            <div className="text-center">
                                                <p className="fw-semibold fs-6">- : -</p>
                                            </div>
                                        </div>
                                        <div className="col-auto mt-3">
                                            <p className="fs-6">{getOpponentData(nextGame.opponent).short}</p>
                                        </div>
                                        <div className="col-auto">
                                            <img src={`https://server.raketefreiburg.de/uploads/${getOpponentData(nextGame.opponent).picture}`} className="ticker" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <table className="table rakete-font text-center">
                                <thead>
                                    <tr className="table-light">
                                        <th>P</th>
                                        <th className="">W</th>
                                        <th>D</th>
                                        <th>L</th>
                                        <th>GD</th>
                                        <th>PTS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{stats.p}</td>
                                        <td>{stats.w}</td>
                                        <td>{stats.d}</td>
                                        <td>{stats.l}</td>
                                        <td>+{stats.gd}</td>
                                        <td>{stats.pts}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                        <Accordion>
                                <Accordion.Item eventKey="0" className="rounded-bottom-1 rounded-0">
                                    <Accordion.Header>
                                    <div className="rakete-font fs-6 fw-semibold">ALLE SPIELE</div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    <table className="table text-center">
                                <tbody >
                                    {games.map((game, index) => (
                                        <tr>
                                            <td className="text-center col-2 fs-8 align-middle">{formatGermanDateandTime(game.date)} Uhr <br /> {game.location} </td>
                                            <td className="text-end col-3 fs-7 align-middle">Rakete Freiburg</td>
                                            <td className="fw-semibold col-2 fs-7 align-middle">{!game.scored && !game.conceded ? ("- : -") : (`${game.scored} : ${game.conceded}`)}</td>
                                            <td className="text-start col-3 fs-7 align-middle">{getOpponentData(game.opponent).name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="rounded shadow-sm mt-3 mx-3 pb-4">
                        <h3 className="rakete-font font-dark text-center pt-4 fs-2"><b>UPCOMING EVENTS</b></h3>
                        <p className="mx-4 fs-6 text-justify">Unsere Turniere sind stets heiß begehrt und oft rasch ausgebucht! Der Raketen-Cup, ein Indoor-Event, findet jedes Jahr im Frühjahr in einer Halle statt, während der Mittsommer-Cup im Sommer draußen auf einem Rasenplatz ausgetragen wird. Diese Events sind nicht nur sportliche Höhepunkte, sondern auch Gelegenheiten, Gemeinschaft zu erleben und Spaß am Fußball zu haben. Sei dabei und erlebe mit uns unvergessliche Momente auf dem Platz!</p>
                        <div className="row mx-auto gap-3 text-black px-3">
                            {tournaments ? tournaments.map((tournament, index) => (
                                <div className="col-md rounded mx-auto mt-4 shadow-sm news-max" key={index}>
                                    <p className="rakete-font rakete-rot text-center text-uppercase fs-4 my-2 mb-2 fw-semibold">{tournament.name}</p>
                                    <img src={`https://server.raketefreiburg.de/uploads/${tournament.picture}`} className="card-img-top rounded-top" alt="..." />
                                    <div className=" pt-3 shadow-sm rounded-bottom">
                                        <p className="rakete-font fs-6 fw-semibold text-center">{formatGermanDateandTime(tournament.date)} Uhr <br />{tournament.location}</p>
                                        <div className="rounded-bottom pb-1 mb-3 py-1 signbutton bg-light">
                                    <p className="rakete-font fs-5 fw-semibold text-uppercase text-center my-2" onClick={() => handleTournamentClick(tournament._id)}>Infos & Anmeldung</p>
                                    </div>
                                    </div>
                                    
                                </div>
                            )) : 
                            <div>
                                <div className="text-center text-secondary my-5 fs-5 fw-semibold text-uppercase rakete-font">Aktuell stehen keine Events an.</div>
                                <div className="text-center my-4">Schau regelmäßig bei uns vorbei, um keine neuen Events zu verpassen!</div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="row mx-3 shadow-sm rounded mt-3 ">
                        <div className="col-lg py-3 ">
                            <div className="align-items-center">
                                <p className="text-center rakete-font fs-3 text-uppercase fw-semibold">Grillfeier & Mitgliederversammlung</p>
                                <p className="text-justify">Wir planen unsere Grillfeiern und Mitgliederversammlungen im Voraus, da wir beliebte Grillhütten in Freiburg nutzen. Getränke werden vom Verein gestellt, und wir freuen uns über Salatspenden oder selbst gebackene Kuchen von unseren Mitgliedern. Jeder bringt sein eigenes Grillgut mit. Dabei tauschen wir nicht nur leckere Speisen aus, sondern auch Ideen und Neuigkeiten über den Verein!</p>
                            </div>
                        </div>
                        <div className="col py-3">
                            <table className="table mb-0">
                                <thead>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="rakete-font">Datum</td>
                                        <td className="rakete-font">Ort</td>
                                    </tr>
                                    {events.map((event, index) => (
                                        <tr id={index}>
                                            <td className="fw-semibold">{formatGermanDateandTime(event.date)} Uhr</td>
                                            <td>{event.location}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Event;