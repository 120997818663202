import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { HiArrowCircleRight } from "react-icons/hi";
import { HiArrowCircleLeft } from "react-icons/hi";
import axios from 'axios';

function NewsDetail({ show, onHide, news }) {
  const [loading, setLoading] = useState(false);
  if (!news) {
    return null;
  }

  const formattedDate = (date) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    return new Date(date).toLocaleDateString('de-DE', options);
  };

  return (
    <Modal size="lg" show={show} onHide={onHide} centered className='no-carot'>
      <Modal.Header className='bg-light justify-content-center border border-0 rakete-rot' closeButton>
        <Modal.Title className='rakete-font fw-semibold text-uppercase'>{news.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-light shadow'>
        <div>
        <img src={`https://server.raketefreiburg.de/uploads/${news.picture}`} className="img-fluid rounded shadow-sm border" alt={`News: ${news.title}`} />
        <p className="fs-7 fw-semibold mx-4 text-end mt-2">{formattedDate(news.date)}</p>
        <p className="mx-3 mb-4 mt-2 fs-6">{news.content}
          
        </p>
        </div>
      </Modal.Body>
      <Modal.Footer className='border border-0 fs-7'>Verfasser: {news.author}</Modal.Footer>
    </Modal>
  );
}

function News() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedNews, setSelectedNews] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [newsPerPage, setNewsPerPage] = useState(3);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get('https://server.raketefreiburg.de/api/News')
      .then((response) => {
        const reversedNews = response.data.data.reverse();
        setNews(reversedNews);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize < 1000) {
      setNewsPerPage(1);
    } else {
      setNewsPerPage(3);
    }
  }, [screenSize]);

  const nextNews = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= news.length) {
        return 0;
      } else {
        return newIndex;
      }
    });
  };

  const prevNews = () => {
    setCurrentIndex((prevIndex) => {
      let newIndex = prevIndex - 1;
      if (newIndex < 0) {
        newIndex = news.length - 1;
      }
      return newIndex;
    });
  };

  const handleNewsClick = (index) => {
    setSelectedNews(news[index]);
    setShowDetail(true);
  };

  const handleHideDetail = () => {
    setShowDetail(false);
  };

  return (
    <div className="bg-light shadow">
      <div className="mx-auto mb-5 container-fluid position-relative">
        <h4 className="rakete-font fw-semibold pt-3 ms-4 rakete-rot anne">NEWS</h4>
        <div className="row justify-content-center">
          {news.slice(currentIndex, currentIndex + newsPerPage).map((news, index) => (
            <div key={index} className={screenSize >= 1000 ? "col-3 mb-5 " : " news-max"}>
              <div className={screenSize < 1000 ? "mb-5 ms-5 me-5" : ""}>
                <div className="card news-card h-100 shadow hover" onClick={() => handleNewsClick(currentIndex + index)}>
                  <img src={`https://server.raketefreiburg.de/uploads/${news.picture}`} className="card-img-top news-ratio" alt={`News: ${news.title}`} />
                  <div className="card-body">
                    <h5 className="text-center fs-6 text-uppercase">{news.title}</h5>
                  </div>
                </div>
              </div>
            </div>
            
          ))}
        </div>
        {currentIndex > 0 && (
          <button className="carousel-control-prev" type="button" onClick={prevNews}>
            <HiArrowCircleLeft className='rakete-rot fs-1' />
            <span className="visually-hidden">Previous</span>
          </button>
        )}
        {currentIndex + newsPerPage < news.length && (
          <button className="carousel-control-next" type="button" onClick={nextNews}>
            <HiArrowCircleRight className='rakete-rot fs-1' />
            <span className="visually-hidden">Next</span>
          </button>
        )}
      </div>
      <NewsDetail show={showDetail} onHide={handleHideDetail} news={selectedNews} />
    </div>
  );
}

export default News;
