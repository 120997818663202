import React from 'react';
import erste from "../assets/teams/thumbnail/erste.webp";
import zweite from "../assets/teams/thumbnail/zweite.webp";
import mixed from "../assets/teams/thumbnail/mixed.webp";
import frauen from "../assets/teams/thumbnail/frauen.webp";
import { Nav } from 'react-bootstrap';


const teamData = [
    { imageSrc: erste, buttonText: 'Erste', link: "/erste"},
    { imageSrc: zweite, buttonText: 'Zweite', link: "/zweite" },
    { imageSrc: mixed, buttonText: 'Mixed', link: "/mixed" },
    { imageSrc: frauen, buttonText: 'Frauen', link: "/frauen" },
];

const TeamCard = ({ imageSrc, buttonText, link }) => {
    return (
        <div className="col team-card text-center pb-4">
            <div className="button-overlay team-img">
                <Nav.Link href={link}>
                <img
                    src={imageSrc}
                    className="img-thumbnail"
                    alt="..."
                />
                <button
                    type="button"
                    className="btn btn-danger rakete-font"
                >
                    {buttonText}
                </button>
                </Nav.Link>

            </div>
        </div>
    );
};

const TeamsSection = () => {
    return (
        <div className="py-2 bg-rakete shadow">
            <div className="container-fluid ">
                <p className="rakete-font my-1 text-end text-white fw-semibold fs-4">TEAMS</p>
                <div className="row justify-content-center py-4">
                    {teamData.map((team, index) => (
                        <TeamCard
                            key={index}
                            imageSrc={team.imageSrc}
                            buttonText={team.buttonText}
                            link={team.link}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TeamsSection;
