import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const easeOutQuad = t => t * (2 - t);
const frameDuration = 1000 / 60;

const CountUpAnimation = ({ children, duration = 2000 }) => {
  const countTo = parseInt(children, 10);
  const [count, setCount] = useState(0);
  const [ref, inView] = useInView();

  useEffect(() => {
    let animationTimeout;

    if (inView) {
      // Verzögerung von 2 Sekunden (2000 Millisekunden) hinzufügen
      animationTimeout = setTimeout(() => {
        let frame = 0;
        const totalFrames = Math.round(duration / frameDuration);
        const counter = setInterval(() => {
          frame++;
          const progress = easeOutQuad(frame / totalFrames);
          setCount(countTo * progress);

          if (frame === totalFrames) {
            clearInterval(counter);
          }
        }, frameDuration);

        return () => clearInterval(counter); // Aufräumen bei Komponentenabbau
      }, 500); // Verzögerung von 2 Sekunden (2000 Millisekunden)
    }

    return () => {
      clearTimeout(animationTimeout); // Clear timeout bei Komponentenabbau
    };
  }, [inView]);

  return <span ref={ref}>{Math.floor(count)}</span>;
};

export default CountUpAnimation;